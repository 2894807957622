.category-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.category-container {
    max-width: 970px;
    margin: 0 auto;
    padding: 20px;
}

.category-title {
    font-size: 24px; /* Smaller font size for desktop */
    margin-bottom: 20px;
    text-align: center;
    color: #353535;
    padding-right: 20px; /* Add right padding */
}

.articles-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.article-item {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    width: calc(33% - 20px);
    box-sizing: border-box;
}

.article-item:hover {
    transform: scale(1.02);
}

.article-image {
    width: 100%;
    height: auto;
    display: block;
}

.article-title2 {
    margin-top: 10px;
    font-size: 18px;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}

.article-date {
    font-size: 10px; /* Smaller font size for desktop */
    margin: 0 20px 15px 20px;
    color: #777;
    display: none;
    text-align: right;
}

@media (max-width: 768px) {
    .article-item {
        width: 100%;
        margin: 0 20px;
        box-sizing: border-box;
    }

    .category-title,
    .article-title,
    .article-date {
        font-size: 16px; /* Adjust font size for mobile */
    }
}
/* Existing styles */
.category-section {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.category-container {
    max-width: 970px;
    margin: 0 auto;
    padding: 20px;
}

.category-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #353535;
    padding-right: 20px;
}

.articles-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.article-item {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    width: calc(33% - 20px);
    box-sizing: border-box;
}

.article-item:hover {
    transform: scale(1.02);
}

.article-image {
    width: 100%;
    height: auto;
    display: block;
}



.article-date {
    font-size: 10px;
    margin: 0 20px 15px 20px;
    color: #777;
    display: none;
    text-align: right;
}

/* Special category styles */
.special-category .article-item {
    background: none;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.special-category .article-image {
    width: 140px;
    height: auto;
    margin-right: 20px;
}

.special-category .article-title2 {
    margin: 10px;
    font-size: 18px;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .article-item {
        width: 100%;
        margin: 0 20px;
        box-sizing: border-box;
    }

    .category-title,
    .article-title,
    .article-date {
        font-size: 16px;
    }
}
