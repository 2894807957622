@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/heebo/v26/NGS6v5_NC0k9P9H0TbFzsQ.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

@font-face {
  font-family: 'IBM Plex Arabic Text';
  src: url('https://components.alarab.com/2024/fonts/IBMPlexArabic-Text.woff2') format('woff2'),
      url('https://components.alarab.com/2024/fonts/IBMPlexArabic-Text.woff') format('woff'),
      url('https://components.alarab.com/2024/fonts/IBMPlexArabic-Text.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Add any additional global font styles or imports here */
