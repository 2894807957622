/* Full-width gradient background for the banner section */
.banner-section {
    width: 100%;
    padding: 40px 0;
    background: linear-gradient(167deg, rgba(7, 130, 145, 1) 0%, rgba(7, 34, 51, 1) 100%);
}

/* Center the banner image */
.banner-container {
    width: 100%;
    text-align: center;
}

.banner-image {
    width: 100%;
    max-width: 700px;
    height: auto;
    
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .banner-section {
        padding: 20px 0; /* Adjust padding for smaller screens */
    }

    .banner-image {
        margin:20px auto;
        max-width: none;
        width: calc(100%  - 40px);
        margin-top:50px
    }
}

/* Article Card Styles */
.imgwrap {
    margin: 20px; /* Add margin between elements on desktop */
}
a {
    text-decoration: none;
}
.imgwrap img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    border: 1px #555 solid;
}

.news-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: 200px; /* Set a fixed height for images */
    object-fit: cover; /* Ensures the image covers the entire area */
    border-radius: 8px; /* Rounded corners for images */
}



.news-title {
    /* padding: 0 20px; */
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: right;
    color: #353535;
    font-weight: 500;
    /* padding: 0 20px; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    color: #000;
}


/* Section Title */
.section-title {
    font-size: 24px;
    margin: 20px 0;
    position: relative;
    background: #000;
    padding: 10px 20px;
    border-radius: 8px;
}

.section-title.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Center and limit the width of the slick section */

.slick-section .carousel-container {
    direction: rtl;
    max-width: 970px;
    margin: 0 auto;
}
.about-us-content {
    direction: rtl;
    max-width: 970px;
    margin: 0 auto;
}
@media (max-width: 768px) {
    .about-us-content {
        
        
        
        padding:20px
    } 
    .slick-section .carousel-container {
        width: 100%;
    }
}

.carousel-container .react-multi-carousel-item {
    transition: transform 0.5s ease-in-out;
}

.carousel-container .react-multi-carousel-list {
    overflow: visible;
}

/* Desktop wrapper styling */
@media (min-width: 769px) {
    .react-multi-carousel-item{

    }
    .imgwrap {
        margin: 0;
    }
    
}

.see-more {
    text-align: center;
    margin: 20px 0;
}

.see-more a {
    text-decoration: none;
    font-size: 16px;
    color: #007BFF;
}

.see-more a:hover {
    text-decoration: underline;
}

.wrapper {
    text-align: center;
    flex-grow: 1;
}
  /* Place text above the image on mobile */
  .react-multi-carousel-item div a {
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
}
/* Mobile adjustments */
@media (max-width: 768px) {
   

    .wrapper {
        border-radius: 0; /* Remove border radius on wrapper */
        border: none; /* Remove border on wrapper */
    }
    .news-title {
        font-size: 20px;
        margin: 0 20px
       }
       .react-multi-carousel-item div a {
        padding:0
       }
 
}
 
.imgwrap img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    border: 0;
    box-shadow: 1px 1px 4px rgb(4 52 73);
    /* margin-top: 20px; */
}
