.comark-signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
  font-size: 10px;
  color: #333;
  padding-bottom:50px ;
}

.comark-logo {
  height: 15px;
}

.comark-text {
  margin-top: 5px; /* Adds some space between the image and text */
  color: #333; /* Color of the text */
  text-decoration: none; /* Removes underline from the link */
}

.comark-text:hover {
  text-decoration: underline; /* Underline on hover */
}


