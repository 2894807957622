.header {
    background-color: transparent; /* Start with transparent background */
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 80px; /* Initial height */
    z-index: 5;
    transition: background-color 0.3s ease, height 0.3s ease; /* Added height transition */
    position: relative
}

.header.scrolled {
    background-color: #1b3743; /* Change background to black when scrolled */
    height: 50px; /* Height when scrolled */
    position: fixed
}

.header-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    transition: height 0.3s ease;
    flex-direction: column;
    max-width: 970px;
    margin: 0 auto;
    position: relative;
}

.logo {
    margin: 0 auto;
    height: 50px; /* Initial logo height */
    transition: height 0.3s ease; /* Added transition for smooth height change */
}

.header.scrolled .logo {
    height: 30px; /* Logo height when scrolled */
}

.title {
    flex-grow: 1;
    font-size: 1.5em;
    margin: 0;
}

.menu-icon, .back-icon, .control-button, .whatsapp-icon {
    font-size: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 35px;
    line-height: 25px;
    font-size: 30px;
    color:#000;

    position: absolute;
    right: 20px;
}
.menu-icon {
    position: absolute;
    left:20px;
    right:auto
}
.whatsapp-icon {
    color: #0ae252;
}

.article-page-controls {
    display: flex;
    align-items: center;
    margin-right: auto;
    flex-direction: row-reverse;
    margin-left:35px
}

.control-button {
    font-size: 1;
    font-size: 20px;
    background: none;
    border: none;
    padding: 0 4px;
}

@media (min-width: 768px) {
    .menu-icon {
        display: none;
    }
}
