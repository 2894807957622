/* Menu Styles */
.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    z-index: 1000;
    transform: translateX(+100%);
    transition: transform 0.3s ease-in-out;
    overflow-y: auto;
}

.menu.open {
    transform: translateX(0);
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu ul li {
    margin: 15px 0;
}

.menu ul li a {
    color: #fffaee;
    text-decoration: none;
    font-size: 18px;
}

/* Language switcher styling */
.language-switcher {
    display: flex;
    justify-content: center;
}

.language-switcher button {
    background-color: transparent;
    border: 2px solid white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    color: white;
}

.language-switcher button.active {
    display: none; /* Hide the active language */
}

.language-switcher button.inactive {
    display: inline-block;
}

/* Mobile-specific Styles */
.menu.mobile {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu.mobile ul {
    flex-direction: column;
    align-items: center;
    margin-right: 40px;
}

/* Desktop-specific Styles */
.menu.desktop {
    position: relative;
    width: 970px;
    height: auto;
    background-color: transparent;
    color: black;
    z-index: 1;
    overflow-y: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 30px;
    border-top: 1px #2fa9ba solid;
    border-bottom: 1px #2fa9ba solid;
}

.menu.desktop ul {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the menu items */
    padding: 0;
    margin: 0;
}

.menu.desktop ul li {
    margin: 0 20px;
}

.menu.desktop ul li a {
    font-size: 14px;
    text-align: center; /* Center the text within each menu item */
    text-decoration: none;
    COLOR:#000
}

.menu.desktop ul li:first-child {
    margin-right: 0;
}

/* Hide close icon on desktop */
.menu.desktop .close-icon {
    display: none;
}

/* Language switcher for desktop */
.menu.desktop .language-switcher {
    position: absolute;
    top: 50%;
    left: 0; /* Align to the left */
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-start;
}

.menu.desktop .language-switcher button {
        /* color: #0fa8be; */
        color: #000;
        font-size: 12px;
        padding: 2px 3px;
        border: 0px solid white;

}

.menu.desktop .language-switcher button:hover {
}
.close-icon {
    font-size: 46px
}