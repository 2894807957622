.article-container {
    max-width: 700px;
    margin: 0 auto;
    direction: rtl;
    padding: 0; /* Remove padding */
    padding: 0 20px 0 20px;

}

.article-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
}
.article-title {
    font-size: 30px;
    margin-bottom: 10px;
    text-align: right;
    color: #000;
    line-height: 140%;
    margin: 0;
    order: 2;
    -webkit-line-clamp: 5;
}

.article-controls {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    order: 1;
}

.font-control, .share-button {
    background: none;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
}

.share-button {
    background: #007bff;
    border: none;
    color: white;
}

.article-content,.gallery-title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: justify;
    color: #000;
    font-weight: 500;
    line-height: 180%;
}

.article-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 20px;
}

.article-writer {
    font-size: 13px;
    line-height: 15px;
    color: #ccc;
    text-align: right;
    font-weight: 500;
    margin: 15px 0 10px 0;
}

/* Responsive Design */
@media (min-width: 768px) {
    .article-header {
        flex-direction: row;
        align-items: center;
    }

    .article-title {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        padding-right: 0;
        order: 1;
    }

    .article-controls {
        margin-right: 20px;
        margin-left: 0;
        order: 2;
    }
}

@media (max-width: 767px) {
    .article-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-controls {
        order: 1;
    }

    .article-title {
        order: 2;
        margin-top: 10px;
    }
}
