body {
  caret-color: transparent;
  font-family: 'IBM Plex Arabic Text', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: 500;
  background: #f7f7f7;
  width: 100%;
  direction: rtl;
}

:lang(he) {
  font-family: 'Heebo';
  direction: rtl; /* Hebrew text should also be RTL */
}

.hebrew-text {
  font-family: Arial, sans-serif;
}

/* Loading screen styles */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(7, 130, 145, 1);
}

.loading-content {
  text-align: center;
}

.loading-logo {
  width: 200px;
  animation: bounce .8s ease-in-out infinite;
}

.loading-text {
  font-size: 13px;
  color: #fff;
}

/* Keyframes for animations */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}
